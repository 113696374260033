import { createTheme } from '@neui/core';

export const baseTheme = createTheme({
  colors: {
    // Base colors
    petrol0: '#FFFFFF',
    petrol100: '#ECEFF2',
    petrol200: '#CED8DA',
    petrol300: '#ABBAC0',
    petrol400: '#83989F',
    petrol500: '#5B7A82',
    petrol600: '#2D5562',
    petrol700: '#002E3C',
    petrol800: '#002530',

    gold0: '#A88F66',
    gold100: '#B49C78',
    gold200: '#BFAA8B',
    gold300: '#CAB89D',
    gold400: '#D5C5B0',
    gold500: '#DFD3C3',
    gold600: '#EAE1D6',
    gold700: '#F4F0EA',
    gold800: '#FFFFFF',

    yellow100: '#FFD700',
    yellow200: '#FFC700',

    red100: '#DB000F',
    red200: '#FF3D23',
    green100: '#009E39',
    green200: '#009435',
    orange100: '#F49300',
    orange200: '#F69C00',

    neutral0: '$petrol0',
    neutral100: '$petrol100',
    neutral200: '$petrol200',
    neutral300: '$petrol300',
    neutral400: '$petrol400',
    neutral500: '$petrol500',
    neutral600: '$petrol600',
    neutral700: '$petrol700',
    neutral800: '$petrol800',

    error: '$red100',
    success: '$green100',
    warning: '$orange100',

    primary: '$yellow100',
    'primary-hover': '$yellow200',
    secondary: '$neutral700',
    'secondary-hover': '$neutral800',
    'secondary-1': '#3A7E8A',
    'secondary-2': '#93C1B4',
    'secondary-3': '#D5DBB6',
    'secondary-4': '#D6C18B',
    'secondary-5': '#BF925E',
    'secondary-6': '#9B5C2F',
    tertiary: '$gold0',
    'tertiary-hover': '$gold100',
    disabled: '$neutral300',

    'text-standard': '$neutral700',
    'text-helper': '$neutral500',
    'text-hover': '$neutral800',
    'text-button': '$petrol800',
    'text-button-inverse': '$neutral0',
    'text-disabled': '$neutral300',

    background: '$neutral0',
    'background-elevated': '$neutral0',
    'background-hover': '$neutral100',
    'background-active': '$neutral200',

    'strong-line': '$petrol600',
  },
  fonts: {
    sans: '"Gotham", "Helvetica Neue", apple-system, sans-serif',
  },
  fontSizes: {
    'desktop-xl-1': '72px',
    'desktop-xl-2': '60px',

    'desktop-m1-1': '60px',
    'desktop-m1-2': '44px',
    'desktop-m1-3': '32px',
    'desktop-m1-4': '22px',
    'desktop-m1-5': '17px',
    'desktop-m1-6': '15px',
    'desktop-m1-7': '13px',

    'desktop-m2-1': '44px',
    'desktop-m2-2': '36px',
    'desktop-m2-3': '27px',

    'mobile-1': '32px',
    'mobile-2': '27px',
    'mobile-3': '22px',
    'mobile-4': '18px',
    'mobile-5': '15px',
    'mobile-6': '13px',
    'mobile-7': '11px',
  },
  lineHeights: {
    'desktop-xl-1': '80px',
    'desktop-xl-2': '68px',

    'desktop-m1-1': '68px',
    'desktop-m1-2': '56px',
    'desktop-m1-3': '40px',
    'desktop-m1-4': '32px',
    'desktop-m1-5': '28px',
    'desktop-m1-6': '24px',
    'desktop-m1-7': '20px',

    'desktop-m2-1': '56px',
    'desktop-m2-2': '40px',
    'desktop-m2-3': '36px',

    'mobile-1': '40px',
    'mobile-2': '36px',
    'mobile-3': '32px',
    'mobile-4': '28px',
    'mobile-5': '24px',
    'mobile-6': '20px',
    'mobile-7': '18px',
  },
  letterSpacings: {
    'desktop-xl-1': '-1px',
    'desktop-xl-2': '-0.4px',

    'desktop-m1-1': '-1px',
    'desktop-m1-2': '-0.4px',
    'desktop-m1-3': '-0.2px',
    'desktop-m1-4': '-0.1px',
    'desktop-m1-5': '0px',
    'desktop-m1-6': '0.1px',
    'desktop-m1-7': '0.3px',

    'desktop-m2-1': '-0.4px',
    'desktop-m2-2': '-0.2px',
    'desktop-m2-3': '-0.2px',

    'mobile-1': '-0.4px',
    'mobile-2': '-0.2px',
    'mobile-3': '-0.1px',
    'mobile-4': '0px',
    'mobile-5': '0.1px',
    'mobile-6': '0.1px',
    'mobile-7': '0.4px',
  },
  space: {
    'component-1': '4px',
    'component-2': '8px',
    'component-3': '12px',
    'component-4': '16px',
    'component-5': '20px',
    'component-6': '24px',
    'component-7': '28px',
    'component-8': '32px',
    'component-9': '40px',
    'component-10': '44px',

    'layout-1': '16px',
    'layout-2': '24px',
    'layout-3': '32px',
    'layout-4': '36px',
    'layout-5': '48px',
    'layout-6': '64px',
    'layout-7': '96px',
    'layout-8': '192px',

    section: '$layout-7',
    'double-subsection': '$layout-6',
    subsection: '$layout-3',
  },
  fontWeights: {
    bold: 700,
    medium: 500,
    book: 300,
  },
});

export const sandTheme = createTheme({
  colors: {
    petrol0: '#F1EFED',
    petrol100: '#E0E0DD',
    petrol200: '#C3CAC8',
    petrol300: '#A0AEAC',
    petrol400: '#778F8D',
    petrol500: '#537173',
    petrol600: '#304D4A',
    petrol700: '#002E3C',
    petrol800: '#002530',
  },
});

export const oceanTheme = createTheme({
  colors: {
    error: '$red200',
    success: '$green200',
    warning: '$orange200',

    petrol0: '#FFFFFF',
    petrol100: '#DBE2E5',
    petrol200: '#B7C4C9',
    petrol300: '#7F959D',
    petrol400: '#5E7B83',
    petrol500: '#426671',
    petrol600: '#1D4856',
    petrol700: '#103D4B',
    petrol800: '#002E3C',

    neutral0: '$petrol800',
    neutral100: '$petrol700',
    neutral200: '$petrol600',
    neutral300: '$petrol500',
    neutral400: '$petrol400',
    neutral500: '$petrol300',
    neutral600: '$petrol200',
    neutral700: '$petrol100',
    neutral800: '$petrol0',
    'text-button-inverse': '$neutral800',
    'background-elevated': '$neutral100',
    'background-hover': '$neutral200',
    // 'background-active': '$neutral300',
  },
});

export const oceanElevatedTheme = createTheme({
  colors: {
    error: '$red200',
    success: '$green200',
    warning: '$orange200',

    petrol0: '#FAFBFC',
    petrol100: '#E9EDF0',
    petrol200: '#CDD7DA',
    petrol300: '#9AACB3',
    petrol400: '#789098',
    petrol500: '#53737C',
    petrol600: '#305764',
    petrol700: '#204A58',
    petrol800: '#103D4B',

    neutral0: '$petrol800',
    neutral100: '$petrol700',
    neutral200: '$petrol600',
    neutral300: '$petrol500',
    neutral400: '$petrol400',
    neutral500: '$petrol300',
    neutral600: '$petrol200',
    neutral700: '$petrol100',
    neutral800: '$petrol0',

    'text-button-inverse': '$neutral800',
  },
});
