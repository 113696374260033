// @Note regenerator-runtime must come first!
import 'regenerator-runtime/runtime';

import { useEffect, type ReactElement, type ReactNode } from 'react';
import { globalStyles } from '@neui/styleguide-commerzbank';
import { globalCss } from '@neui/core';

import { baseTheme } from 'styling/stitches.config';
import { BuildConfig, RuntimeSettingsContextProvider } from '@utils/config';
import { TrackerContextProvider } from '@utils/snowplowTracking';
import { makeServer } from '@utils/mirage';
import useScrollTracker from '@utils/scrollTracker';

import type { AppProps } from 'next/app';
import type { NextPage } from 'next';

export type NextPageWithLayout<P> = NextPage<P> & {
  getLayout?: (page: ReactElement, props: P) => ReactNode;
};

type AppConfigProps = {
  config: BuildConfig;
};

export type PageProps<T> = AppConfigProps & T;

type AppPropsWithLayout = AppProps<AppConfigProps> & {
  Component: NextPageWithLayout<any>;
};

const customGlobalStyles = globalCss({
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  h1: {
    margin: '0 !important',
  },
});

export default function MyApp({
  Component,
  pageProps,
}: AppPropsWithLayout): React.ReactNode {
  if (
    pageProps.config.server.chat.enable &&
    pageProps.config.server.chat.enableMock
  ) {
    makeServer({ environment: 'development', config: pageProps.config });
  }

  globalStyles();
  customGlobalStyles();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  // Initialize GA4 dataLayer
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
  }, []);
  useScrollTracker(pageProps.config.server.tracking.ga4.eventContext);

  return (
    <RuntimeSettingsContextProvider config={pageProps.config}>
      <TrackerContextProvider>
        {getLayout(
          <div className={`${baseTheme}`}>
            <Component {...pageProps} />
          </div>,
          pageProps,
        )}
      </TrackerContextProvider>
    </RuntimeSettingsContextProvider>
  );
}
