interface EventData {
  [key: string]: unknown;
}

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[];
  }
}

export type EventContext =
  | 'Service Portal'
  | 'Service Portal Gpp-de'
  | 'Service Portal Gpp-en';

export function trackEvent(eventName: string, data: EventData) {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer.push({
    event: eventName,
    ...data,
  });
}

export function GA4TrackAccordionOpen(
  eventContext: EventContext,
  sidelayerName: string,
  valueSelected: string,
) {
  window?.dataLayer?.push({
    event: 'accordionOpen',
    eventContext,
    sidelayerName: sidelayerName,
    linkLocation: 'Sidelayer',
    valueSelect: valueSelected,
  });
}

export type GA4SearchType =
  | 'Direct entry'
  | 'Search suggestion'
  | 'Default suggestion'
  | 'Typo suggestion'
  | 'Typo correction' //tbi: to be implemented
  | 'Unknown';

function shouldHideQuery(searchType: GA4SearchType): boolean {
  return searchType === 'Direct entry' || searchType === 'Unknown';
}

export function GA4TrackInternalSearch(
  eventContext: EventContext,
  query: string,
  searchType: GA4SearchType,
  pageType: GA4PageType,
  searchTermPosition?: number,
) {
  window?.dataLayer?.push({
    event: 'internalSearch',
    eventContext,
    searchTerm: shouldHideQuery(searchType) ? 'direct search' : query,
    searchType: searchType,
    pageType,
    pageTemplate: undefined,
    searchTermPosition,
    formData: { searchTermBQ: query },
  });
}

export type GA4PageType =
  | 'Homepage'
  | 'Article Page'
  | 'Category Page'
  | 'Landing Page'
  | 'Search Page'
  | 'Result Page'
  | '404 Page';

export type GA4PageTemplate = 'Article Simple' | 'Article Multiple' | 'Video';

export function GA4TrackPageView(
  eventContext: EventContext,
  pageUrl: string,
  title: string,
  pageType: GA4PageType,
  pageTemplate: GA4PageTemplate | undefined,
  previousPage: string,
) {
  window?.dataLayer?.push({
    event: 'pageView',
    eventContext,
    pageType,
    pageTemplate: pageTemplate ?? pageType,
    pageUrl: pageUrl,
    pageTitle: title,
    previousPage,
  });
}
export function GA4TrackSideLayerOpen(
  eventContext: EventContext,
  label: string,
) {
  window?.dataLayer?.push({
    event: 'sidelayerOpen',
    eventContext,
    sidelayerName: label,
  });
}
export function GA4TrackSearchResultClick(
  eventContext: EventContext,
  query: string,
  buttonLabel: string,
  targetPageUrl: string | undefined,
  resultPosition: number,
  searchType: GA4SearchType,
  totalResults: number,
  resultPageNumber: number,
  badgeName: string,
) {
  window?.dataLayer?.push({
    event: 'searchResultClick',
    eventContext,
    valueSelect: 'link',
    searchType,
    searchTerm: shouldHideQuery(searchType) ? 'direct search' : query,
    numberOfResults: totalResults,
    resultPosition,
    resultPageNumber,
    targetPageUrl,
    resultCategory: badgeName,
    buttonLabel,
    formData: { searchTermBQ: query },
  });
}

export function GA4TrackSearchResultInlineWidgetClick(
  eventContext: EventContext,
  buttonLabel: string,
  targetPageUrl: string | undefined,
) {
  window?.dataLayer?.push({
    event: 'searchResultClick',
    eventContext,
    valueSelect: 'button',
    targetPageUrl,
    buttonLabel,
  });
}
export function GA4TrackSearchResultLoad(
  eventContext: EventContext,
  query: string,
  searchType: GA4SearchType,
  resultPageNumber: number,
  totalResults: number,
) {
  window?.dataLayer?.push({
    event: 'searchResultsLoad',
    eventContext,
    searchType: searchType,
    searchTerm: shouldHideQuery(searchType) ? 'direct search' : query,
    resultPageNumber,
    numberOfResults: totalResults,
    formData: { searchTermBQ: query },
  });
}

export function GA4TrackNavigationClick(
  eventContext: EventContext,
  event: 'navigationClick' | 'mainNavigationClick',
  navigationElement:
    | 'Breadcrumb'
    | 'Subcategory Chip'
    | 'OB ActionButton'
    | 'ActionButton'
    | 'quick_access',
  navigationLabel: string,
  targetPageUrl: string | undefined,
) {
  window?.dataLayer?.push({
    event,
    eventContext,
    navigationElement,
    navigationLabel,
    targetPageUrl,
  });
}
export function GA4TrackFeedbackButtonClick(
  eventContext: EventContext,
  feedbackContext: 'Video' | 'Article',
  buttonLabel: string,
  valueSelect: string,
  formData?: { [key: string]: boolean | string },
) {
  window?.dataLayer?.push({
    event: 'feedbackButtonClick',
    eventContext,
    feedbackContext,
    buttonLabel,
    valueSelect,
    formData,
  });
}
export function GA4TrackTeaserCtaClick(
  eventContext: EventContext,
  teaserType:
    | 'CTA Contact Teaser'
    | 'CTA Contact Feedback'
    | 'CTA Related Questions'
    | 'Widget CTA'
    | 'Widget QR Code'
    | 'CTA Card'
    | 'CTA Picture',
  teaserName: string,
  linkLabel: string,
  targetPageUrl?: string,
  sectionName?: string,
  sectionNumber?: number,
  sectionTotal?: number,
) {
  window?.dataLayer?.push({
    event: 'teaserCtaClick',
    eventContext,
    teaserType,
    teaserName,
    linkLabel,
    sectionName,
    targetPageUrl,
    sectionNumber,
    sectionTotal,
  });
}
export function GA4TrackLinkClick(
  eventContext: EventContext,
  targetPageUrl: string | undefined,
  linkLabel: string,
  sidelayerName?: string,
  linkLocation?: 'Article Page' | 'Sidelayer' | 'Homepage',
  subcategory?: string,
  sectionName?: string,
  sectionNumber?: number,
  sectionTotal?: number,
) {
  window?.dataLayer?.push({
    event: 'linkClick',
    eventContext,
    targetPageUrl: targetPageUrl,
    linkLabel,
    sidelayerName,
    linkLocation,
    valueSelect: subcategory, // undefined for contact Layer
    sectionName,
    sectionNumber,
    sectionTotal,
  });
}
export function GA4TrackReachSection(
  eventContext: EventContext,
  sectionName: string,
  sectionNumber: number,
  sectionTotal: number,
) {
  window?.dataLayer?.push({
    event: 'reachSection',
    eventContext,
    sectionName,
    sectionNumber,
    sectionTotal,
  });
}

export function GA4TrackScrollPercentage(
  eventContext: EventContext,
  scrollDepth: number,
) {
  window?.dataLayer?.push({
    event: 'scrollPercentage',
    eventContext,
    scrollDepth,
  });
}
